import { formatDateView } from './DateUtils';

export function downloadPdf(pdfData: any, name: string) {
  const url = window.URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.pdf`);
  document.body.appendChild(link);
  link.click();
}

export function createReportName(serialNumber: string, endDate: string) {
  return `${serialNumber}--${formatDateView(endDate)}.pdf`;
}
