





























































































































import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { TranslateResult } from 'vue-i18n';
import { AxiosResponse } from 'axios';

import { ITEMS_PER_PAGE_OPTIONS } from '@/core/Constants';
import { ReportFiltersState } from '@/store/report';
import { ReportCreateResponse } from '@/http/ReportsHttpModel';
import { createReportName, downloadPdf } from '@/core/Report';
import { formatDatePicker } from '@/core/DateUtils';
import ReportTemplate from '../containers/ReportTemplate.vue';

interface Select {
  text: string;
  value: string;
}

export type ValidationRules = Record<string, (value: string) => boolean | TranslateResult>;

export default Vue.extend({
  components: {
    ReportTemplate,
  },
  computed: {
    reportRules(): ValidationRules {
      return {
        machineRequired: (value: string) => !!value || this.$t('Report.Machine is required'),
        startDateRequired: (value: string) => !!value || this.$t('Report.Start date is required'),
        endDateRequired: (value: string) => !!value || this.$t('Report.End date is required'),
      };
    },
    ...mapGetters('user', ['isUserFullReportData']),
  },
  data() {
    return {
      filterDrawer: false,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
      showDatePicker1: false,
      showDatePicker2: false,
      filters: {
        machineFilter: '',
        startTimeFromFilter: null as string | null,
        startTimeToFilter: null as string | null,
      } as ReportFiltersState,
      loading: true,
      generateReportLoading: false,
      machineListSelect: [] as Select[],
      reportPreview: null as ReportCreateResponse | null,
      generatingPdfLoading: false,
      valid: false,
    };
  },
  mounted() {
    this.filters.startTimeToFilter = formatDatePicker(new Date().toString()) ?? '';

    this.$http.reports.reportsLastReportDate().then((resp) => {
      const { last_report_date } = (resp as any).data;
      if (last_report_date) {
        this.filters.startTimeFromFilter = formatDatePicker(last_report_date) ?? '';
      }
    });

    this.$http.machine.machineList().then((resp) => {
      this.machineListSelect = resp.data.results.map((m) => {
        if (!m.uuid) {
          throw new Error('Received machine with empty uuid');
        }
        return {
          text: m.serial_number,
          value: m.uuid,
        };
      });
    });
  },
  methods: {
    ...mapActions(['showSuccessSnackbar', 'showFailedSnackbar']),

    printReport() {
      this.generatingPdfLoading = true;
      this.$http.instance
        .get(`/reports/${this.reportPreview?.uuid}/pdf/`, { responseType: 'arraybuffer' })
        .then((resp) => {
          downloadPdf(
            resp.data,
            createReportName(
              this.reportPreview?.snapshot.machine.serial_number ?? '',
              ((this.reportPreview?.end_date as unknown) as string) ?? '',
            ),
          );
        })
        .finally(() => {
          this.generatingPdfLoading = false;
        });
    },

    newReport() {
      (this.$refs.form as any).validate();

      if (this.valid) {
        this.generateReportLoading = true;
        this.$http.reports
          .reportsCreate({
            begin_date: this.formatApiDate(this.filters.startTimeFromFilter) ?? '',
            end_date: this.formatApiDate(this.filters.startTimeToFilter) ?? '',
            machine_uuid: this.filters.machineFilter,
          })
          .then((reportResponse) => {
            const report = (reportResponse as unknown) as AxiosResponse<ReportCreateResponse>;

            this.reportPreview = report.data;

            this.showSuccessSnackbar(this.$t('Report.Report generated'));
          })
          .catch(() => {
            this.showFailedSnackbar(this.$t('Report.Error creating report'));
          })
          .finally(() => {
            this.generateReportLoading = false;
          });
      }
    },
    formatApiDate(date: string | undefined): string | undefined {
      if (date) {
        return new Date(date).toISOString().split('.')[0];
      }
      return undefined;
    },
  },
});
