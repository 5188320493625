








































import Vue from 'vue';
import { mapActions } from 'vuex';

import { ITEMS_PER_PAGE_OPTIONS } from '@/core/Constants';
import { formatDateTimeView } from '@/core/DateUtils';
import { ReportSerialiser } from '@/http/myApi';
import { ReportListQueryParams } from '@/http/ReportListQueryParams';
import { ListOptions } from '@/models/ListsModel';
import { AppState } from '@/store';
import { TranslateResult } from 'vue-i18n';

export default Vue.extend({
  computed: {
    reportOptions: {
      get: function(): ListOptions {
        return (this.$store.state as AppState).process.options;
      },
      set: function(newValue: ListOptions) {
        this.$store.commit('process/setOptions', newValue);
      },
    },
    items(): {
      machine__serial_number: string | undefined;
      begin_date: string | undefined;
      end_date: string | undefined;
      created_date: string | undefined;
      uuid: string | undefined;
    }[] {
      return this.reportItems.map((report) => {
        return {
          uuid: report.uuid,
          machine__serial_number: report.machine?.serial_number,
          begin_date: formatDateTimeView(report.begin_date),
          end_date: formatDateTimeView(report.end_date),
          created_date: formatDateTimeView(report.created_date),
        };
      });
    },
    headers(): {
      text: string | TranslateResult;
      value: string;
      align?: string;
      sortable?: boolean;
    }[] {
      return [
        {
          text: this.$t('Report.Machine'),
          align: 'start',
          value: 'machine__serial_number',
        },
        {
          text: this.$t('Report.Created date'),
          value: 'created_date',
        },
        { text: this.$t('Report.Begin date'), value: 'begin_date' },
        { text: this.$t('Report.End date'), value: 'end_date' },
      ];
    },
  },
  data() {
    return {
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,

      totalItems: 0,
      options: {} as ListOptions,
      reportItems: [] as ReportSerialiser[],
      loading: true,
    };
  },

  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },

  mounted() {
    this.options = { ...this.reportOptions };
  },
  methods: {
    ...mapActions(['showFailedSnackbar']),
    pageUpdated() {
      this.reportOptions = { ...this.options };
    },
    getOptionsToOrdering(options: ListOptions) {
      if (!(options.sortBy?.length || options.sortDesc?.length)) return undefined;

      return (options.sortDesc[0] ? '-' : '') + options.sortBy[0];
    },
    getDataFromApi() {
      const paramsAdapter: ReportListQueryParams = {
        page: this.options.page,
        page_size: this.options.itemsPerPage,
        ordering: this.getOptionsToOrdering(this.options),
      };

      this.$http.reports
        .reportsList(paramsAdapter)
        .then((resp) => {
          this.reportItems = resp.data.results;

          this.totalItems = resp.data.count;
        })
        .catch(() => this.showFailedSnackbar(this.$t('Reports.Cannot fetch list')))
        .finally(() => (this.loading = false));
    },
  },
});
