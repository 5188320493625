














































































































































































































import Vue, { PropType } from 'vue';
import { formatDateView, formatTimeView } from '@/core/DateUtils';
import {
  addBrackets,
  getPressureUnit,
  getTemperatureUnit,
} from '@/core/UnitsUtils';
import { ReportDetailsResponse } from '@/http/ReportsHttpModel';
import { PROCESS_TYPES_VALUE_MAP_VIEW } from '@/models/ProcessTypeEnum';
import { BussinessType } from '@/models/BusinessTypeEnum';

type ReportItem = {
  lp: string;
  date: string;
  timeFromTo: string;
  processType: string;
  processNumber: string;
  testTime: string;
  testTemperature: { min: number; max: number };
  testPressure: { min: number; max: number };
  result: string;
  description: string;
};

type SummaryItem = {
  lp: string;
  processType: string;
  processCount: string;
};

export default Vue.extend({
  props: {
    report: {
      type: Object as PropType<ReportDetailsResponse>,
      required: true,
    },
  },
  data(): {
    BussinessType: typeof BussinessType;
  } {
    return {
      BussinessType: BussinessType,
    };
  },
  computed: {
    reportItems(): ReportItem[] {
      if (!this.report?.snapshot?.processes.length) {
        return [];
      }
      return this.report.snapshot.processes.map((p, idx) => {
        return {
          lp: idx + 1 + '',
          date: formatDateView(p.start_date.toString()) ?? '',
          timeFromTo: `${formatTimeView(
            p.start_date.toString(),
          )} - ${formatTimeView((p as any).end_date)}`,
          processType: p.process_type_name,
          processNumber: p.execution_counter + '',
          testTime: `${new Date(p.seconds_taken * 1000)
            .toISOString()
            .substr(14, 5)}`,
          testTemperature: {
            min: p.temp_min.toFixed(1),
            max: p.temp_max.toFixed(1),
          },
          testPressure: {
            min: p.pressure_min.toFixed(2),
            max: p.pressure_max.toFixed(2),
          },
          result: p.end_status_codes.length
            ? `${p.end_status_codes}`
            : this.$t('Success').toString(),
          errors: p.end_status_codes.length,
          description: p.user_comment,
        };
      });
    },
    summaryItems(): SummaryItem[] {
      if (!this.report?.snapshot?.summary) {
        return [];
      }
      return Object.keys(this.report.snapshot.summary).map((s, idx) => {
        return {
          lp: idx + 1 + '',
          processType: (PROCESS_TYPES_VALUE_MAP_VIEW as any)[+s],
          processCount: (this.report.snapshot.summary as any)[+s],
        };
      });
    },
    reportBusinessType(): string {
      if (this.report.snapshot.workplace.business_type === null) {
        return '-';
      }
      return this.$t(
        'Account.' +
          BussinessType[+this.report.snapshot.workplace.business_type],
      ).toString();
    },
    reportCreatedDate(): string {
      return formatDateView(this.report.created_date) ?? '';
    },
    reportDateRange(): string {
      return `${formatDateView(
        this.report.begin_date.toString(),
      )} - ${formatDateView(this.report.end_date.toString())}`;
    },
    reportProcessTypes(): string {
      return this.report.processes_result_list;
    },
    processDataHeaders(): {
      text: string;
      value: string;
      align?: string;
      divider?: boolean;
    }[] {
      return [
        {
          text: this.$t('Report.ProcessHeaders.No').toString(),
          align: 'start',
          value: 'lp',
          divider: true,
        },
        {
          text: this.$t('Report.ProcessHeaders.Date').toString(),
          value: 'date',
          divider: true,
        },
        {
          text: this.$t('Report.ProcessHeaders.Process time').toString(),
          value: 'timeFromTo',
          divider: true,
        },
        {
          text: this.$t(
            'Report.ProcessHeaders.Process name / Process type',
          ).toString(),
          value: 'processType',
          divider: true,
        },
        {
          text: this.$t('Report.ProcessHeaders.Process number').toString(),
          value: 'processNumber',
          divider: true,
        },
        {
          text: this.$t('Report.ProcessHeaders.Time').toString(),
          value: 'testTime',
          divider: true,
        },
        {
          text: `${this.$t(
            'Report.ProcessHeaders.Temperature',
          ).toString()} ${addBrackets(
            getTemperatureUnit(this.report.snapshot.metric_units),
          )}`,
          value: 'testTemperature',
          divider: true,
        },
        {
          text: `${this.$t(
            'Report.ProcessHeaders.Pressure',
          ).toString()} ${addBrackets(
            getPressureUnit(this.report.snapshot.metric_units),
          )}`,
          value: 'testPressure',
          divider: true,
        },
        {
          text: this.$t('Report.ProcessHeaders.Result').toString(),
          value: 'result',
          divider: true,
        },
        {
          text: this.$t(
            'Report.ProcessHeaders.Packages number / Packages types / description',
          ).toString(),
          value: 'description',
        },
      ];
    },
    summaryHeaders(): {
      text: string;
      value: string;
      align?: string;
      divider?: boolean;
    }[] {
      return [
        {
          text: this.$t('Report.SummaryHeaders.No').toString(),
          align: 'start',
          value: 'lp',
          divider: true,
        },
        {
          text: this.$t('Report.SummaryHeaders.Process type').toString(),
          value: 'processType',
          divider: true,
        },
        {
          text: this.$t('Report.SummaryHeaders.Process number').toString(),
          value: 'processCount',
          divider: true,
        },
      ];
    },
    sumOfSummary(): number {
      return Object.keys(this.report.snapshot.summary).reduce((acc, curr) => {
        const x = (this.report.snapshot.summary as any)[+curr] as number;
        acc += x;
        return acc;
      }, 0);
    },
  },
});
