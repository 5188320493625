




















import Vue from 'vue';
import ReportContainer from '../containers/ReportContainer.vue';
import ThemeWrapper from '../containers/ThemeWrapper.vue';

export default Vue.extend({
  components: {
    ReportContainer,
    ThemeWrapper,
  },
  data() {
    return {
      onlyCheck: true,
    };
  },
});
