


















import Vue from 'vue';
import ReportContainer from '../containers/ReportContainer.vue';

export default Vue.extend({
  components: {
    ReportContainer,
  },
  data() {
    return {
      onlyCheck: false,
    };
  },
});
