




















import { formatDateTimeView } from '@/core/DateUtils';
import { createReportName, downloadPdf } from '@/core/Report';
import { ReportDetailsResponse } from '@/http/ReportsHttpModel';
import Vue from 'vue';
import { Breadcrumps } from '../views/ProcessSingle.vue';
import ReportTemplate from './ReportTemplate.vue';

export default Vue.extend({
  components: {
    ReportTemplate,
  },
  props: {
    onlyCheck: {
      type: Boolean,
    },
  },
  data() {
    return {
      reportData: null as ReportDetailsResponse | null,
      uuid: '',
      generatingPdf: false,
    };
  },
  computed: {
    breadcrumps(): Breadcrumps {
      return [
        {
          text: this.$t('Report.My reports').toString(),
          disabled: false,
          to: { name: 'ReportList' },
          exact: true,
        },
        {
          text: formatDateTimeView(this.reportData?.created_date) ?? '',
          disabled: true,
          href: 'Report',
        },
      ];
    },
  },
  mounted() {
    this.uuid = this.$route.params.id;
    this.fetchReportData(this.uuid);
  },
  methods: {
    fetchReportData(id: string) {
      this.$http.reports.reportsRead(id).then((report) => {
        this.reportData = report.data as ReportDetailsResponse;
      });
    },
    printReport() {
      this.generatingPdf = true;
      this.$http.instance
        .get(`/reports/${this.uuid}/pdf/`, { responseType: 'arraybuffer' })
        .then((resp) => {
          downloadPdf(
            resp.data,
            createReportName(
              this.reportData?.snapshot.machine.serial_number ?? '',
              this.reportData?.end_date ?? '',
            ),
          );
        })
        .finally(() => {
          this.generatingPdf = false;
        });
    },
  },
});
